import React, { FC } from "react";
import { Header, Segment } from "semantic-ui-react";
import Application from "./Application";

const Apply: FC = () => {

    return (
        <React.Fragment>
            <Segment>
                <Header as={'h2'}>
                    Tilmelding til babysalmesang ved Birgitte Stenhøj Andresen
                </Header>
                <p>
                    Birgitte har babysalmesangshold i fire nedenstående kirker.  
                </p>
                <p>
                Hvis du er interesseret i at deltage i babysalmesang, vælges først den kirke, det drejer sig om, og dernæst indtastes dine oplysninger. Herved sendes en forespørgsel om deltagelse, og du vil høre nærmere, når forespørgslen er modtaget.
                </p>
            </Segment>
            <Application/>
        </React.Fragment>
    )
}

export default Apply;