import React from "react";
import { NavLink } from "react-router-dom";
import { Header, Menu, Message, Segment } from "semantic-ui-react";
import { Routing } from "../constants/routes";

const Index = (): React.ReactElement => {
  return (
    <Segment.Group >
        <Segment>
          <Header size={'huge'}>Velkommen</Header>
        </Segment>
        <Segment style={{minHeight: '30vh'}}>
          <Message>
            <p>Musikfavn er en musikfaglig hjemmeside om babysalmesang.</p>
            <p>Lige nu er siden under genopbygning, men indtil videre kan du indsende forespørgsler på at deltage i babysalmesangsforløb v. Birgitte Stenhøj Andresen i en af følgende kirker:</p>
          </Message>
          <Menu size={'tiny'} compact={true} color={'green'}>
              <Menu.Item
                  as={NavLink}
                  to={`/${Routing.APPLY}/virklund`}
                  content={'Virklund'}
              />
              <Menu.Item
                  as={NavLink}
                  to={`/${Routing.APPLY}/linaa`}
                  content={'Linå'}
              />
              <Menu.Item
                  as={NavLink}
                  to={`/${Routing.APPLY}/sejs`}
                  content={'Sejs-Svejbæk'}
              />
              <Menu.Item
                  as={NavLink}
                  to={`/${Routing.APPLY}/mariehoj`}
                  content={'Mariehøj'}
              />
          </Menu>
        </Segment>
    </Segment.Group>
  );
}

export default Index;
