import React, {
  FC,
  FormEvent,
  SyntheticEvent,
  useContext,
  useState,
} from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  CheckboxProps,
  Form,
  Header,
  Input,
  InputOnChangeData,
  Menu,
  Message,
  Segment,
} from "semantic-ui-react";
import { IApplyForm } from "../../../constants/interfaces";
import { Routing } from "../../../constants/routes";
import { Collection } from "../../../stores/fireBaseStore";
import { StoreContext } from "../../../stores/rootStore";

enum ApplyForm {
  PARENT = "parent",
  ADDRESS = "address",
  CITY = "city",
  BABY = "baby",
  EMAIL = "email",
  BABY_BIRTHDATE = "birthdate",
  ATTENDANCE_RECORD = "attendance_record",
  GDPR_ACCEPT = "gdpr_accept",
}

const Application: FC = () => {
  const store = useContext(StoreContext);
  const [form, setForm] = useState<IApplyForm>({
    venue: "",
    baby: "",
    birthdate: "",
    parent: "",
    address: "",
    city: "",
    attendance_record: "",
    email: "",
    gdpr_accept: false,
  });
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [gdprError, setGdprError] = useState<
    { content: string; pointing: string } | undefined
  >(undefined);
  const [submitted, setSubmitted] = useState<boolean>(false);

  const url = "/tilmeld";
  const navigate = useNavigate();
  const venue: { venue?: string } = useParams();
  let church = "";

  switch (venue.venue) {
    case undefined:
      break;
    case "sejs":
      church = "Sejs-Svejbæk";
      break;
    case "mariehoj":
      church = "Mariehøj";
      break;
    case "linaa":
      church = "Linå";
      break;
    case "virklund":
      church = "Virklund";
      break;
    default:
      navigate(url);
  }

  const updateFormData = (
    event: SyntheticEvent,
    data: InputOnChangeData | CheckboxProps
  ): void => {
    const value =
      data.name === ApplyForm.GDPR_ACCEPT ? !!data.checked : data.value;

    if (ApplyForm.GDPR_ACCEPT && value) {
      setGdprError(undefined);
    }

    setForm({
      ...form,
      [data.name as ApplyForm]: value,
    });
  };

  const submitApplication = async (e: FormEvent) => {
    e.preventDefault();
    if (!form.gdpr_accept) {
      setGdprError({
        content: "Accepter venligst vilkårene",
        pointing: "left",
      });
      return;
    }
    setSubmitting(true);
    form.venue = church;

    try {
      await store.store.FireBaseStore.create(Collection.APPLICATIONS, form);
      await store.store.FireBaseStore.create(Collection.MAIL, {
        to: ["birgitte@musikfavn.dk"],
        message: {
          subject: `Ny tilmelding: ${church}`,
          html: `
                        <div>Tilmelding med GDPR accept fra: ${form.email}</div>
                        <div>
                            <a href="https://musikfavn.dk/admin/login">Gå til musikfavn</a>
                        </div>
                    `,
        },
      });
      setSubmitted(true);
    } catch (e) {
      alert("Der er sket en fejl - din tilmelding kunne ikke registreres");
    }
    setSubmitting(false);
    // history.push(Routing.APPLIED);
  };

  /**
   * Forældres Navn, Babys fornavn, adresse, kirke, mobilnummer, email, babys fødselsdato, antal gange, gdpr accept
   */
  return (
    <Segment>
      {submitted ? (
        <Message
          positive={true}
          header={"Tak for din forespørgsel til Babysalmesang!"}
          content={"Du vil modtage en mail om nærmere detaljer snarest!"}
        />
      ) : (
        <React.Fragment>
          <Header as={"h3"}>Vælg den kirke, der hører til dit sogn</Header>
          <Menu size={"tiny"} compact={true} color={"green"}>
            <Menu.Item
              as={NavLink}
              to={`/${Routing.APPLY}/virklund`}
              content={"Virklund"}
            />
            <Menu.Item
              as={NavLink}
              to={`/${Routing.APPLY}/linaa`}
              content={"Linå"}
            />
            <Menu.Item
              as={NavLink}
              to={`/${Routing.APPLY}/sejs`}
              content={"Sejs-Svejbæk"}
            />
            <Menu.Item
              as={NavLink}
              to={`/${Routing.APPLY}/mariehoj`}
              content={"Mariehøj"}
            />
          </Menu>
          {venue.venue && (
            <Form
              style={{ marginTop: "2em" }}
              onSubmit={submitApplication}
              disabled={submitting}
              name={venue.venue}
              loading={submitting}
            >
              <Form.Field required={true}>
                <Input
                  disabled={submitting}
                  name={ApplyForm.BABY}
                  label={"Babys fornavn"}
                  required={true}
                  onChange={updateFormData}
                />
              </Form.Field>
              <Form.Field required={true}>
                <Input
                  disabled={submitting}
                  name={ApplyForm.BABY_BIRTHDATE}
                  label={"Babys fødselsdato"}
                  type={"date"}
                  required={true}
                  placeholder={"fx. 01-01-2022"}
                  onChange={updateFormData}
                />
              </Form.Field>
              <Form.Field required={true}>
                <Input
                  name={ApplyForm.PARENT}
                  required={true}
                  label={"Forælders fulde navn"}
                  onChange={updateFormData}
                />
              </Form.Field>
              <Form.Field required={true}>
                <Input
                  name={ApplyForm.ADDRESS}
                  required={true}
                  label={"Adresse"}
                  onChange={updateFormData}
                />
              </Form.Field>
              <Form.Field required={true}>
                <Input
                  name={ApplyForm.CITY}
                  required={true}
                  label={"Postnr. og by"}
                  onChange={updateFormData}
                />
              </Form.Field>
              <Form.Field required={true}>
                <Input
                  disabled={submitting}
                  required={true}
                  name={ApplyForm.EMAIL}
                  label={"Email-adresse"}
                  onChange={updateFormData}
                />
              </Form.Field>
              <Form.Field required={true}>
                <Input
                  disabled={submitting}
                  name={ApplyForm.ATTENDANCE_RECORD}
                  label={"Bemærkninger"}
                  onChange={updateFormData}
                  placeholder={
                    "Herunder noteres, hvis du har deltaget på tidligere hold enten med denne baby eller en storesøskend."
                  }
                />
              </Form.Field>
              <Segment size={"tiny"}>
                <Header
                  content={
                    "Samtykkeerklæring i forhold til registrering af kontaktdata"
                  }
                />
                <p>
                  Jeg giver hermed samtykke til, at min kontaktdata må
                  registreres og benyttes af {church} Sogns musikpædagog,
                  Birgitte Stenhøj Andresen, i forbindelse med afvikling af
                  babysalmesangsforløb.
                </p>
                <p>
                  Jeg giver også samtykke til, at denne kontaktdata må gemmes i
                  op til 5 år.
                </p>
                <Header
                  content={"Tilbagekaldelse af samtykke"}
                  size={"small"}
                />
                <p>
                  Du er berettiget til, på hvilket som helst tidspunkt, at
                  tilbagetrække dit samtykke. Såfremt du vælger at trække dit
                  samtykke tilbage, påvirker dette alene den fremadrettede
                  behandling og dermed ikke lovligheden af den brug, der er
                  baseret på samtykket inden din tilbagetrækning heraf.
                  Tilbagekaldelse af samtykke kan ske ved at rette henvendelse
                  til musikpædagog ved {church} kirke, Birgitte Stenhøj
                  Andresen, på e-mail:{" "}
                  <a href={"mailto:birgitte@musikfavn.dk"}>
                    birgitte@musikfavn.dk
                  </a>
                </p>
              </Segment>
              <Form.Checkbox
                onChange={updateFormData}
                name={ApplyForm.GDPR_ACCEPT}
                label={"Jeg accepterer ovenstående samtykkeerklæring."}
                error={gdprError}
              />
              <Button disabled={submitting} color={"green"} type={"submit"}>
                Send forespørgsel
              </Button>
            </Form>
          )}
        </React.Fragment>
      )}
    </Segment>
  );
};
export default Application;
