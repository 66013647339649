import React, { FC, useContext, useEffect, useState } from "react";
import { Checkbox, Segment, Table } from "semantic-ui-react";
import { Collection } from "../../../stores/fireBaseStore";
import { StoreContext } from "../../../stores/rootStore";
import { IApplyForm } from "../../../constants/interfaces";
import { Timestamp } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const displayDate = (timestamp?: Timestamp) => {
  if (!timestamp) {
    return "";
  }

  const date = timestamp.toDate();
  return `${date.toLocaleDateString("da-DK")} ${date.toLocaleTimeString(
    "da-DK"
  )}`;
};
const Applications: FC = () => {
  getAuth();
  const [applications, setApplications] = useState<IApplyForm[]>([]);
  const context = useContext(StoreContext);

  useEffect(() => {
    async function getApplications() {
      const applications = await context.store?.FireBaseStore?.getAll(
        Collection.APPLICATIONS
      );
      applications.sort((a, b) => {
        if (!a.createdAt) {
          return -1;
        }
        if (!b.createdAt) {
          return 1;
        }
        return b.createdAt?.seconds - a.createdAt?.seconds;
      });
      setApplications(applications);
    }

    getApplications();
  }, [context.store]);

  return (
    <Segment>
      <h1 className={"mf-heading"}>Forespørgsler</h1>
      <Table compact={true} striped={true}>
        <Table.Header>
          <Table.HeaderCell>Modtaget</Table.HeaderCell>
          <Table.HeaderCell>Babys fornavn</Table.HeaderCell>
          <Table.HeaderCell>Fødselsdato</Table.HeaderCell>
          <Table.HeaderCell>Forælders navn</Table.HeaderCell>
          <Table.HeaderCell>Adresse</Table.HeaderCell>
          <Table.HeaderCell>By</Table.HeaderCell>
          <Table.HeaderCell>Email</Table.HeaderCell>
          <Table.HeaderCell>Kirke</Table.HeaderCell>
          <Table.HeaderCell>Bemærkninger</Table.HeaderCell>
          <Table.HeaderCell>GDPR samtykke</Table.HeaderCell>
        </Table.Header>
        {applications.map((application) => (
          <Table.Row key={application.baby}>
            <Table.Cell>{displayDate(application.createdAt)}</Table.Cell>
            <Table.Cell>{application.baby}</Table.Cell>
            <Table.Cell>{application.birthdate}</Table.Cell>
            <Table.Cell>{application.parent}</Table.Cell>
            <Table.Cell>{application.address}</Table.Cell>
            <Table.Cell>{application.city}</Table.Cell>
            <Table.Cell>{application.email}</Table.Cell>
            <Table.Cell>{application.attendance_record}</Table.Cell>
            <Table.Cell>{application.venue}</Table.Cell>
            <Table.Cell>
              <Checkbox disabled={true} checked={application.gdpr_accept} />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table>
    </Segment>
  );
};

export default Applications;
