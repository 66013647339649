import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AdminRouting, Routing } from "./constants/routes";
import Index from "./views";
import About from "./views/About";
import Apply from "./views/Apply";
import HeaderMenu from "./views/components/HeaderMenu";
import { Login } from "./views/Login";
import Applications from "./views/Login/Applications";


const App = (): React.ReactElement => {
  
  return (
    <BrowserRouter>
        <div style={{margin: '0 auto', maxWidth: 1000}}>
            <Routes>
              <Route path={'/'} element={<HeaderMenu/>}>
                <Route index={true} element={<Index/>}/>
                <Route path={`${Routing.ABOUT}`} element={<About/>}/>
                <Route path={`${Routing.APPLY}`} element={<Apply/>}/>
                <Route path={`${Routing.APPLY}/:venue`} element={<Apply/>}/>
                <Route path={'*'} element={<span>No path</span>}/>
              </Route>
              <Route path={'/admin'}>
                <Route path={AdminRouting.LOGIN} element={<Login/>}/>
                <Route path={AdminRouting.APPLICATIONS} element={<Applications/>}/>
              </Route>

            </Routes>
          </div>
    </BrowserRouter>
  );
}

export default App;
