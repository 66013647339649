import React from "react";
import {Grid, Segment } from "semantic-ui-react";

export default function About() {
  return (
          <Segment>
            <Grid columns={2}>
              <Grid.Column>
                <h1 className={'mf-heading'}>Musikfavns formål</h1>
                <p><strong>Helt overordnet har hjemmesiden til hensigt at inspirere til babysalmesang. Gennem både teoretiske og praktiske overvejelser og forskelligt konkret, musikalsk materiale, er formålet at synliggøre det store potentiale, babysalmesang byder på.</strong></p>

                <p>En hjemmeside kan ikke gengive essensen i babysalmesang, for nærværet og babysalmesangens helt specielle rum opstår i den musikalske situation og kan som sådan ikke indfanges. Men hjemmesiden kan ses som en belysning af forskellige områder af babysalmesangen og som en understøttelse heraf.</p>

                <p><em>Hjemmesiden henvender sig til alle med interesse for det musikalske samvær med helt små børn.</em></p>

                <p>Som underviser kan man finde teoretisk understøttelse for babysalmesang og teoretiske overvejelser omkring mødet med det lille barn i undervisningen. Derudover kan man finde praktiske overvejelser omkring opbygning af undervisningen samt konkret materiale og værktøjer til brug i planlægningen. Der er forslag til forskellige musikalske og aktivitetsmæssige muligheder i materialet, og forhåbningen er, at det vil inspirere til, at man som underviser selv får nye ideer.</p>

                <p>Hjemmesiden giver ikke en opskrift på, hvordan man “laver babysalmesang”, for hele essensen i undervisning ligger i den musikalske og personlige formidling. Hjemmesiden kan ses som en form for inspirationsværktøj og en opfordring til både teoretisk refleksion og praktiske overvejelser.</p>

                <p>Som forælder kan man bruge hjemmesiden som et sted at få mere teoretisk viden om babysalmesang og få indblik i forskellige praktiske overvejelser omkring undervisningen. Ved at give en nem tilgang til konkrete sange og salmer er det forhåbningen, at det vil inspirere til brug af musikken derhjemme, så det musikalske samvær bliver en naturlig del af hverdagen.</p>
              </Grid.Column>
              <Grid.Column></Grid.Column>
            </Grid>
              
        </Segment>
  );
}
