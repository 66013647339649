import React from 'react';
import { Link, NavLink, Outlet } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';
import { Routing } from '../../../constants/routes';
import './menu.css';

class HeaderMenu extends React.Component  {

    render(): React.ReactElement {
        return (
            <React.Fragment>
                <div id={"topbar"}>
                    <Link to={'/'}>
                        <div id="header"><h1>MUSIKFAVN</h1><span>En musikpædagogisk hjemmeside om babysalmesang</span></div>
                    </Link>
                </div>

                <Menu style={{marginBottom: 20}} secondary={true} pointing={true}>    
                    <Menu.Item
                        as={NavLink}
                        name={'apply'}
                        to={Routing.APPLY}
                    >
                        Tilmeld
                    </Menu.Item>
                </Menu> 
                <Outlet/>
            </React.Fragment>
        )
    }
}
export default HeaderMenu;