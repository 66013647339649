export enum Routing {
    HOME = '/',
    ABOUT = 'om',
    APPLY = 'tilmeld',
    APPLIED = 'tilmeld/indsendt',
    CONTACT = 'contact'
}

export enum AdminRouting {
    LOGIN = 'login',
    APPLICATIONS = 'applications'
}

export interface MFRoute {
    url: string;
    name: string;
    route: string;
    children?: {[key: string]: MFRoute}
}

export const MFRouting: {[key: string]: MFRoute}  = {
    HOME : {
        url: '/',
        route: '/',
        name: 'Forside'
    },
    APPLY: {
        url: '/tilmeld',
        route: '/tilmeld/:location',
        name: 'Tilmeld'
    }

}