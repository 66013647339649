import React from 'react';
import FireBaseStore from "./fireBaseStore";
import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check"

declare global {
  interface Window { FIREBASE_APPCHECK_DEBUG_TOKEN: boolean }
}
export class RootStore {

    FireBaseStore: FireBaseStore;

    isAuthorised = false;

    constructor(){
        const firebaseConfig = {
            apiKey: "AIzaSyDQlUJ07y3xpgdvnNO7cbU1nD2OEMpJKqY",
            authDomain: "musikfavn.firebaseapp.com",
            databaseURL: "https://musikfavn.firebaseio.com",
            projectId: "musikfavn",
            storageBucket: "musikfavn.appspot.com",
            messagingSenderId: "72826533360",
            appId: "1:72826533360:web:94b748625c031931aa1935",
            measurementId: "G-PLX6GW66LS",
          };
    
          // Initialize Firebase
          const firebaseApp = initializeApp(firebaseConfig);
          this.FireBaseStore = new FireBaseStore(firebaseApp);
          
          //window.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
          const appCheck = initializeAppCheck(firebaseApp, {
            provider: new ReCaptchaV3Provider('6LcZNXMgAAAAAAgetgxfn8zb1V6KnDSTjlFrRC-I'),
          });

          console.log(appCheck);
    }

    // auth(){
    //     const storedToken = localStorage.getItem('mf-auth-token');

    //     console.log('auth', storedToken);
    //     if (!storedToken) {
    //         return undefined;
    //     }
        
    //     const token = JSON.parse(storedToken);
    
    //       if (!token || !token.user ) {
    //         var provider = new firebase.auth.GoogleAuthProvider();
            
    //         firebase.auth()
    //         .getRedirectResult()
    //         .then((result: any) => {
    //           if (!result.user) {
    //             firebase.auth().signInWithRedirect(provider);
    //           }
    
    //           if (result.credential) {
    //             /** @type {firebase.auth.OAuthCredential} */
    //             var credential = result.credential;
    
    //             // This gives you a Google Access Token. You can use it to access the Google API.
    //             var token = credential.accessToken;
    //             // ...
    //           }
    //           // The signed-in user info.
    //           var user = result.user;
    //           localStorage.setItem('mf-auth-token', JSON.stringify(result));
    //         }).catch((error: any) => {
    //           // Handle Errors here.
    //           var errorCode = error.code;
    //           var errorMessage = error.message;
    //           // The email of the user's account used.
    //           var email = error.email;
    //           // The firebase.auth.AuthCredential type that was used.
    //           var credential = error.credential;
    //           // ...
    //         });
    //       }
    // }
}

export const global = {
  store: new RootStore()
}

export const StoreContext = React.createContext(global);