import { FirebaseApp } from "firebase/app";
import { getFirestore, collection, query, getDocs, Firestore, DocumentData, DocumentReference, serverTimestamp, addDoc, Timestamp } from "firebase/firestore";
import { IApplyForm, IMailNotification } from "../constants/interfaces";


export enum Collection {
    APPLICATIONS = 'applications',
    MAIL = 'mail'
}

export default class FireBaseStore {

    db: Firestore;

    constructor(firebaseApp: FirebaseApp){
        this.db = getFirestore(firebaseApp);
    }

    async getAll(collectionName: Collection): Promise<IApplyForm[]> {
        const q = query(collection(this.db, collectionName));
        const queryResult = await getDocs(q);

        const results: IApplyForm[] = [];

        queryResult.forEach( (doc) => {
            results.push(doc.data() as IApplyForm);
        })
        return results;
    }

    async create(collectionName: Collection, entity: IApplyForm | IMailNotification ): Promise<DocumentReference<DocumentData>> {
        entity.createdAt = serverTimestamp() as Timestamp;
        
        return await addDoc(collection(this.db, collectionName), entity);
    }
}