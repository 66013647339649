import React, { FC } from "react";
import { Button, Container } from "semantic-ui-react";
import {
    getAuth,
    onAuthStateChanged,
    User,
    GoogleAuthProvider,
    signInWithPopup,
  } from 'firebase/auth';
import { useNavigate } from "react-router-dom";

export const Login: FC = () => {

    const navigate = useNavigate();
    onAuthStateChanged(getAuth(), authStateObserver);

    function authStateObserver(user: User | null) {
        if(user) {
            navigate('/admin/applications');
        }
    } 

    async function signIn() {
        const provider = new GoogleAuthProvider();
        await signInWithPopup(getAuth(), provider);
    }

    return (
        <Container textAlign={'center'} style={{marginTop: '30%'}}>
            <Button content={'Login'} primary={true} onClick={signIn}/>
        </Container>
    )
}